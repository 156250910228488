<template>
    <div class="modal fade show" id="importTagsModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_importFromXLSFile") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="kt-section kt-section--first">
                        <h3 class="kt-section__title">{{ $t("common_importStepOneText") }}</h3>
                        <div role="alert" class="alert alert-secondary">
                            <div class="alert-icon">
                                <i class="flaticon-info kt-font-brand"></i>
                            </div>
                            <div class="alert-text">{{ $t("tag_importTagsHelpText") }}</div>
                        </div>
                        <div class="kt-section__body kt-align-center">
                            <a download :href="templateImportTags" class="btn btn-success btn-sm btn-bold kt-margin-l-10" id="importTags_downloadExcelTemplate">
                                <i class="fa fa-file-excel kt-margin-r-5"></i>
                                {{ $t("common_downloadXlsTemplate") }}
                            </a>
                        </div>

                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>

                        <h3 class="kt-section__title">{{ $t("common_importStepTwoText") }}</h3>

                        <div class="kt-section__body">
                            <div class="form-group kt-margin-b-0">
                                <label>{{ $t("common_fileBrowser") }}</label>
                                <div class="custom-file">
                                    <input type="file" @change="handleFileSelected" ref="xlsFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept=".xlsx" id="importTagsInputFile" />
                                    <label class="custom-file-label" id="importTagsInputLabel" for="customFile">{{ $t("common_chooseFile") }}</label>
                                    <span class="form-text text-muted">{{ $t("gtw_pleaseSelectXLSFile") }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="isXlsFileIsEmpty" class="kt-font-danger marginTMinus15">{{ $t("common_fileIsEmpty") }}</div>
                    <div v-if="isSheetNotFound" class="kt-font-danger marginTMinus15">{{ $t("tag_tagsSheetNotFound") }}</div>

                    <div v-show="errorDisplayed" class="kt-section kt-margin-t-15 kt-margin-b-0">
                        <div class="kt-font-danger kt-margin-t-15 kt-margin-b-15">{{ $t("common_invalidXlsFile") }}</div>
                        <div class="kt-scroll" data-scroll="true" data-height="160">
                            <div class="kt-section__content">
                                <table class="table kt-font-danger table-sm">
                                    <thead>
                                        <tr>
                                            <th class="kt-align-center">{{ $t("common_rowNumber") }}</th>
                                            <th class="kt-align-center">{{ $t("error_message") }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(errorObj, i) in this.xlsErrors" :key="i">
                                            <td class="kt-align-center font12" scope="row">{{ errorObj.row + 1 }}</td>
                                            <td v-if="errorObj.error == 'required'" class="kt-align-center font12">{{ $t("common_fieldIsRequired", { value: errorObj.column }) }}</td>
                                            <td v-else class="kt-align-center font12">{{ $t(errorObj.error, { value: errorObj.value }) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="importTagsModalImportButton" type="button" @click="importTags" v-bind:disabled="isImportButtonDisabled || localIsUploadTagsInProgress" :class="{ 'btn btn-brand kt-margin-r-5 kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': localIsUploadTagsInProgress, 'btn btn-brand kt-margin-r-5': !localIsUploadTagsInProgress }">
                        {{ $t("common_import") }}
                    </button>
                    <button id="importTagsModalCancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">
                        {{ $t("common_cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import readXlsxFile from "read-excel-file";
const regexUtil = require("../../constants/regex");

export default {
    data() {
        return {
            errorDisplayed: false,
            xlsData: null,
            isImportButtonDisabled: true,
            xlsErrors: null,
            xlsFile: null,
            isXlsFileIsEmpty: false,
            isSheetNotFound: false,
            tagTypesList: [],
            localIsUploadTagsInProgress: false,
            templateImportTags: "/assets/xls-templates/template_import_tags.xlsx"
        };
    },
    created: function() {
        console.log("Component(ImportTagsModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(ImportTagsModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(ImportTagsModal)::destroyed() - called");
        this.resetTagTypesState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        isUploadTagsInProgress: function() {
            this.localIsUploadTagsInProgress = this.isUploadTagsInProgress;
        },
        showImportTagsModal() {
            console.log("Component(ImportTagsModal)::watch(showImportTagsModal) called");
            // Get list of tagtype (here and not in created()) to avoid error error_invalidToken at loading app (before signin)
            if (!this.tagTypeObjsByName || this.tagTypesList.length === 0) {
                this.getTagTypes({siteId: this.$route.params.siteId});
            }
            if (this.showImportTagsModal) {
                console.log("Component(ImportTagsModal)::showImportTagsModal() - show modal");
                $("#importTagsModal").modal("show");
            }
            this.resetShowImportTagsModal();
        },
        hideImportTagsModal() {
            console.log("Component(ImportTagsModal)::watch(hideImportTagsModal) called");
            if (this.hideImportTagsModal) {
                console.log("Component(ImportTagsModal)::hideImportTagsModal() - hide modal");
                $("#importTagsModal").modal("hide");
            }
            this.resetHideImportTagsModal();
        },
        tagTypeObjsByName(list) {
            console.log("Component(ImportTagsModal)::watch(tagTypeObjsByName) called with : ", list);
            let mySelf = this;
            mySelf.tagTypesList = [];
            if (list && Object.keys(list).length > 0) {
                mySelf.tagTypesList = Object.keys(list);
            }
        },
        currentSite(site) {
            console.log("Component(ImportTagsModal)::watch(currentSite) called with : ", site);
            if (site && site.BLEGatewaysType === "OMNIACCESS_OEM") {
                this.templateImportTags = "/assets/xls-templates/template_import_OEM_tags.xlsx"
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["showImportTagsModal", "hideImportTagsModal", "tagTypeObjsByName", "isUploadTagsInProgress", "currentSite"])
    },
    methods: {
        ...mapActions(["massImportTags", "resetShowImportTagsModal", "resetHideImportTagsModal", "getTagTypes", "resetTagTypesState"]),
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        handleFileSelected() {
            console.log("Component(ImportTagsModal)::handleFileSelected() - called");

            this.errorDisplayed = false;
            this.isXlsFileIsEmpty = false;
            this.isSheetNotFound = false;

            if (this.$refs.xlsFile.files.length > 0) {
                this.xlsFile = this.$refs.xlsFile.files[0];
                let mySelf = this;
                const schema = {
                    "Tag Mac Address": {
                        prop: "macAddress",
                        type: String,
                        required: true,
                        parse(value) {
                            if (!regexUtil.macAddress.test(value)) {
                                throw new Error("error_badMacAddressFormat");
                            }
                            return value.toUpperCase();
                        }
                    },
                    "Type of Tag": {
                        prop: "tagType",
                        type: String,
                        required: true,
                        parse(value) {
                            let valueUC = typeof value === "string" ? value.toUpperCase() : value;
                            if (!mySelf.tagTypesList.includes(valueUC)) {
                                if (mySelf.currentSite.BLEGatewaysType === "OMNIACCESS_OEM") {
                                    throw new Error("error_wrongArubaTagTypeValue");
                                } else {
                                    throw new Error("error_wrongTagTypeValue");
                                }
                            }
                            return valueUC;
                        }
                    },
                    "Serial Number": {
                        prop: "serialNumber",
                        required: true,
                        type: String,
                        parse(value) {
                            // Force to return as String (in case extracted as integer)
                            let valueSN = value.toString();
                            if (valueSN.length < 4) {
                                throw new Error("error_wrongSnLength");
                            }
                            return valueSN;
                        }
                    },
                    "Battery Installation Date (YYYY-MM-DD)": {
                        prop: "batteryInstallationDate",
                        type: Date,
                        parse(value) {
                            const formats = ["YYYY-MM-DD", "MM/DD/YYYY"];
                            const batteryInstallationDate = moment(value, formats, true);
                            if (!batteryInstallationDate || !batteryInstallationDate.isValid()) {
                                throw new Error("error_badDateFormat");
                            }
                            return new Date(value).toISOString();
                        }
                    }
                };

                readXlsxFile(this.xlsFile, { schema, sheet: "tags" })
                    .then(({ rows, errors }) => {
                        if (rows.length == 0 && errors.length == 0) {
                            this.isXlsFileIsEmpty = true;
                            return;
                        }

                        const arrayOfMacAddresses = _.map(rows, "macAddress");
                        var allTags = _.compact(arrayOfMacAddresses);
                        var occurrences = allTags.reduce(function(obj, item) {
                            obj[item] = (obj[item] || 0) + 1;
                            return obj;
                        }, {});
                        // Check manually for mac addresses unicity in entore file
                        for (let macAddr in occurrences) {
                            if (occurrences[macAddr] > 1) {
                                // Push an error
                                errors.push({
                                    error: "error_macAddressesMustHaveDifferentsValues",
                                    value: macAddr
                                });
                            }
                        }

                        if (errors.length > 0) {
                            this.errorDisplayed = true;
                            this.isImportButtonDisabled = true;
                            this.xlsErrors = errors;
                        } else {
                            this.errorDisplayed = false;
                            this.isImportButtonDisabled = false;
                            this.xlsData = rows;
                        }
                    })
                    .catch(err => {
                        if (err.message.indexOf('Sheet "tags" not found') != -1) {
                            this.isSheetNotFound = true;
                        }
                    });
            }
        },
        importTags() {
            console.log("Component(ImportTagsModal)::importTags() - called");

            let tags = [];
            for (var currentLine of this.xlsData) {
                let obj = {};
                if (currentLine.hasOwnProperty("macAddress")) {
                    obj.macAddress = currentLine.macAddress;
                }
                if (currentLine.hasOwnProperty("serialNumber")) {
                    obj.serialNumber = currentLine.serialNumber;
                }
                if (currentLine.hasOwnProperty("tagType")) {
                    if (this.tagTypeObjsByName) {
                        let tagTypeObj = this.tagTypeObjsByName[currentLine.tagType];
                        if (tagTypeObj) {
                            obj.tagTypeId = tagTypeObj.id;
                        }
                    }
                }
                if (currentLine.hasOwnProperty("batteryInstallationDate")) {
                    obj.batteryInstallationDate = currentLine.batteryInstallationDate;
                }
                tags.push(obj);
            }
            let data = { siteId: this.$route.params.siteId, tags: tags };
            this.massImportTags(data);
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.cursorPointer {
    cursor: pointer;
}
</style>
<style>
.font12 {
    font-size: 12px;
}
</style>
